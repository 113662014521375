import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './already.component.html',
  styleUrls: []
})
export class AlreadyComponent implements OnInit {
  public email: any;
  ngOnInit() {
    
  }
}
