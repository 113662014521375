import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import * as CryptoJS from 'crypto-js';
declare function GetConfig()
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //DomainName = ["localhost","192.168.0.121"];
  DomainName = GetConfig().WhitelistDomain;
  WhitelistDomainForpostmessage = GetConfig().WhitelistDomainForpostmessage;
  //DomainName = ["lms.cashbackloans.local", "my.cashbackloans.com","chat.cashbackloans.com","lms.cashbackloans.com"];
  //DomainName = ["salesplanner.org"];
   baseUrl = GetConfig().ServiceUrl; 
  //baseUrl = 'http://localhost:3005/';
  //baseUrl = 'https://chat.cashbackloans.com/server/';
  //baseUrl = 'https://salesplanner.org/demo/livemessage/server/'
  notifyTrigger = GetConfig().UtilUrl;
  HideSearchIconFor=GetConfig().hidesearchicon
  // notifyTrigger = 'http://localhost:3020/notifychatapp';
  //notifyTriggers = 'https://plt-stg-members.sparklms.com/api/mesage-portal-notification';
  ClosemodelreportURL=GetConfig().ClosemodelreportURL
  ENDCRYPT_DECRYPT_KEY='9BA569F84818BE66';
  constructor(private _http: HttpClient) {
  }
  
  encryptString(data){
    const key = this.ENDCRYPT_DECRYPT_KEY;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    const enc = CryptoJS.AES.encrypt(data, keyutf, { iv: iv });
    const encStr = enc.toString();
    return encStr;
  }
  encryptArray(data){
    var encString = CryptoJS.AES.encrypt(JSON.stringify(data), this.ENDCRYPT_DECRYPT_KEY).toString();
    return encString;
  }
  
  decryptString(data){
    const key = this.ENDCRYPT_DECRYPT_KEY;
    const keyutf = CryptoJS.enc.Utf8.parse(key);
    const iv = CryptoJS.enc.Base64.parse(key);
    const dec = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Base64.parse(data) }, keyutf, {iv: iv	});
    const decStr = CryptoJS.enc.Utf8.stringify(dec)
    return decStr;
  };
  
  decrypt(data){
    var bytes = CryptoJS.AES.decrypt(data, this.ENDCRYPT_DECRYPT_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };
  getTypeRequest(url) {
    return this._http.get(`${this.baseUrl}${url}`).pipe(map(res => {
      return res;
    }));
  }
//
  postTypeRequest(url, payload) {
    return this._http.post(`${this.baseUrl}${url}`, payload).pipe(map(res => {
      return res;
    }));
  }

  postThirdparyurl(url, payload) {
    return this._http.post(`${url}`, payload).pipe(map(res => {
      return res;
    }));
  }

  putTypeRequest(url, payload) {
    return this._http.put(`${this.baseUrl}${url}`, payload).pipe(map(res => {
      return res;
    }));
  }

  upload(file):Observable<any> {

    // Create form data
    const formData = new FormData();
      
    // Store form name as "file" with file data
    formData.append("file", file, file.name);
      
    // Make http post request over api
    // with formData as req
    return this._http.post(this.baseUrl+'message/upload', formData);
  }

}
