import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import {NgForm} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  isLogin: boolean = false;
  errorMessage: any;
  errorStatus: any;

  constructor(
    private _api: ApiService, 
    private _auth: AuthService, 
    private _router:Router
  ) { }
  iTypeSelected: boolean;
  selectInput(event) {
    let selected = event.target.value;
    if (selected == "User") {
      this.iTypeSelected = true;
    } else {
      this.iTypeSelected = false;
    }
  }
  ngOnInit() {

    this.isUserLogin(); 

  }


  
  onSubmit(form: NgForm) {

    if(form.valid){
      this._api.postTypeRequest('user/register', form.value).subscribe((res: any) => {
        if (res.status) { 
          this.errorMessage = res.data;
          this.errorStatus = res.status;
          form.reset();
          // this._auth.setDataInLocalStorage('userData', JSON.stringify(res.data));  
          // this._auth.setDataInLocalStorage('token', res.token);  
          // this._router.navigate(['login']);
        } else { 
          this.errorMessage = res.data;
          this.errorStatus = res.status;
        }
      });
    } else{
      this.errorMessage = "Please fill all the mantatory fields";
    }

  }

  navlogin(){
    this._router.navigate(['/login']);
  }

  isUserLogin(){
    
    if(this._auth.getUserDetails() != null){

        this.isLogin = true;
    }

  }

}