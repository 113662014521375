import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from '../auth/components/login/login.component';
import { RegisterComponent } from '../auth/components/register/register.component';
import { UnauthorizedComponent } from '../auth/components/unauthorized/unauthorized.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { SnoozeComponent } from './components/snooze-alert/snooze-alert.component';


@NgModule({
  declarations: [
    LoginComponent, 
    SnoozeComponent,
    RegisterComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
    
  ],
  exports: [
    LoginComponent, 
    SnoozeComponent,
    RegisterComponent,
    UnauthorizedComponent
  ]
})
export class AuthModule { }