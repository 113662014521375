declare var require: any
import { Component, OnInit,AfterViewInit, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
declare var $: any;
import Swal from 'sweetalert2';
import * as moment from 'moment';
var moments = require('moment-timezone');
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './message.component.html',
  styleUrls: ['../../../assets/css/min-bootstrap.min.css','../../../assets/css/font-awesome.min.css','../../../assets/css/chat.css','./message.component.css']
})
export class MessageComponent implements OnInit,AfterViewInit {
  editID: any;
  messages: any[];
  message: string = '';
  repMessage: string = '';
  friendsList: any;
  selectedFriendName: any;
  selectedFriendId: any;
  loading: boolean = false;
  UserId: any;
  UserName: any;
  FrndId: any;
  isEmojiPickerVisible: boolean;
  repisEmojiPickerVisible: boolean;
  fileName: any;
  config : any;
  file: File = null;
  adminId : any;
  msgType : any;
  sessID : any;
  adminReq : any[];
  chathide : any;
  endsession : any;
  adminnotconnect : any;
  endsess : any;
  convertbtn_id : any;
  aid : any;
  EnbleSerch:boolean=false
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  snoozedataobj:any={}
  enablesnooze:boolean=false
  @ViewChild('selctedsnoozeata') snoozeInput: ElementRef;
  constructor(private _api: ApiService, private _auth: AuthService,private _router:Router,private idle: Idle,private keepalive: Keepalive,private spinner:NgxSpinnerService){
    this._api.getTypeRequest('user/getsettings').subscribe((res:any) => {
       idle.setIdle(res.data[0].idletime);
    });
  }

  showSnoozeLastDayOption: boolean = false;
selectedSnoozedate:any
showSnoozeList = false;

  toggleSnoozeListView() {
    this.showSnoozeList = !this.showSnoozeList;
    if(this.showSnoozeList){
      $('.showSnoozeList').show()
      $('#userlistview').addClass('userlistview')
    }else{
      $('.showSnoozeList').hide()
      $('#userlistview').removeClass('userlistview')
    }
  }
  getPSTTime() {
    //var options = { timeZone:'America/Los_Angeles',hour12: false};
    let date1 = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles"
    });
    return date1
  }
  snoozepickeddatetime:any=''
  submitpck(){
    $('.DatepickerModel').hide();
    //console.log(this.snoozepickeddatetime)
    this.selectedSnoozedate = moment($('#selctedsnoozeata').val()).format('YYYY-MM-DD HH:mm:ss');
   
    this.snoozepickeddatetime=''
    this.confirmsnoozeyes();
  }
  cancelpick(){
    $('.DatepickerModel').hide();
    this.snoozepickeddatetime=''
  }
  GetSnoozedateoption(day){
    const currentMoment = moment();
    if(day=='later'){
    let date  = moment().set({
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0
      }).format('YYYY-MM-DD HH:mm:ss');
      return this.DateFormat(date)
    }else{
    let date = moment(currentMoment).add(1, 'day').set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
      }).format('YYYY-MM-DD HH:mm:ss');
      return this.DateFormat(date)
    }
    
  }
  DateFormat(date){
    return moment(date).format("MM/DD/YYYY hh:mm ")
    }
    settingsVal:any
    isOpenDayfordatepicker = (date) => {
      const now = moments(date).tz(this.settingsVal.timezone);
      var daySplit = this.settingsVal.offdays.split(",");
      //log(daySplit.indexOf(now.format('ddd')) !== -1) 
      return daySplit.indexOf(now.format('ddd')) !== -1;
    }
snoozeChat(option: string) {
  const currentmoments = moment();

  if (option === 'pickdatetime') {
    $('.DatepickerModel').show();

      
    $('#selctedsnoozeata').datetimepicker({
      dayOfWeekStart: 1,
      lang: 'en',
      minDate: this.getPSTTime(),
      step: 5,
      format: 'm/d/Y H:i',
      formatDate: 'm/d/Y',
      formatTime: 'H:i',
      minTime: new Date(this.getPSTTime()),
      beforeShowDay: (date) => {
       
        const isOpenDay = this.isOpenDayfordatepicker(date);
        var isDisabled = isOpenDay;
        return [!isDisabled, ''];
     
      },
      onChangeDateTime: function(ct, $input) {
        // Handle onChangeDateTime event if needed
      },
      onClose: function(ct, $input) {
        // Handle onClose event if needed
      }
    });
      setTimeout(() => {
        this.snoozeInput.nativeElement.focus();
      }, 0);
  } else if (option === 'latertoday') {
    // Set selectedSnoozedate to today at 10 am (PST)
    this.selectedSnoozedate = moment().set({
      hour: 15,
      minute: 0,
      second: 0,
      millisecond: 0
    }).format('YYYY-MM-DD HH:mm:ss');
    this.confirmsnoozeyes();
  } else if (option === 'tomorrow') {
    // Set selectedSnoozedate to tomorrow at 10 am (PST)
    this.selectedSnoozedate = moment(currentmoments).add(1, 'day').set({
      hour: 8,
      minute: 30,
      second: 0,
      millisecond: 0
    }).format('YYYY-MM-DD HH:mm:ss');
    this.confirmsnoozeyes();
  } else {
    // Handle other options (if needed)
  }
 
}
confirmsnoozeno(){
  $('.confirmsnooze').hide();
  this.selectedSnoozedate =''
}
confirmRemoveSnooze(){
  $('.confirmRemoveSnooze').show();
}
confirmRemoveSnoozeyes(){
  $('.confirmRemoveSnooze').hide();
  this.spinner.show()
  
  let data={
    touserid:this.FrndId,
    userid:this.UserId 
  }
  this._api.postTypeRequest('messages/DeleteSnooze',data).subscribe((res:any)=>{
    //console.log(res)
    this.spinner.hide()
    if(res.status==1){
      this._api.postTypeRequest('snooze-alert', {userid:this.UserId}).subscribe((res: any) => {});
     // Swal.fire('Success',"Snooze deleted successfully","info")
  this.snoozedataobj={}
  this.GetSnoozeData()
    }else{
      //console.log("removed failed")
     // Swal.fire('Info!',"Error occured while processing","info")
    }
  },err=>{
    this.spinner.hide()
    //console.log("removed err",err)
   // Swal.fire('Info!',"Error occured while processing","info")
  })
}
confirmRemoveSnoozeno(){
  $('.confirmRemoveSnooze').hide();
}

confirmsnoozeyes(){
  $('.confirmsnooze').hide();
  this.spinner.show()
  let data={
    touserid:this.FrndId,
    snoozeDate:this.selectedSnoozedate,
    userid:this.UserId 
  }
  this._api.postTypeRequest('messages/InsertSnooze',data).subscribe((res:any)=>{
    //console.log(res)
    this.spinner.hide()
    if(res.status==1){
      this._api.postTypeRequest('snooze-alert', {userid:this.UserId}).subscribe((res: any) => {});
  //console.log("added success fully")
 // Swal.fire('Success',"Snooze added sucessfully","success")
  this.GetSnoozeData()
    }else{
      //console.log("added failed")
     // Swal.fire('Info!',"Error occured while processing","info")
    }
  },err=>{
   // Swal.fire('Info!',"Error occured while processing","info")
    this.spinner.hide()
    //console.log("added err",err)
  })
}


 
updateShowSnoozeLastDayOption() {
  const currentTime = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles"
  });
 
  const currentHour = new Date(currentTime).getHours();
  this.showSnoozeLastDayOption = currentHour < 12;
}

GetSnoozeData(){
  //console.log(this.FrndId)
  this.spinner.show()
  let data ={
    type :2,
    userid:this.UserId,
    touserid:this.FrndId
  }
  this._api.postTypeRequest('messages/GetSnoozeData',data).subscribe((res:any)=>{
    //console.log("GetSnoozeData",res)
    this.spinner.hide()
    if(res.status == 1){
   if(res.data.length && this.FrndId){
      this.snoozedataobj=res.data[0]
    }else{
      this.snoozedataobj={}
    }
    }else{
      //console.log(res)
      this.snoozedataobj={}
    }
  
  },err=>{
    this.spinner.hide()
    this.snoozedataobj={}
    //console.log(err)
  })
}
isPSTDaylightSavingTime(): boolean {
  const currentTime = new Date();
  const currentMonth = currentTime.getMonth() + 1;
  return currentMonth >= 3 && currentMonth <= 11;
}
  

  ngAfterViewInit(){
   
    // $('#snoozeDropdownMenu').on('click', this.toggleDropdown);
    // $(document).on('click', this.closeDropdown);
     this.idle.setTimeout(20);
     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
 
     this.idle.onIdleEnd.subscribe(() => { 
       this.idleState = 'No longer idle.'
       this.reset();
     });
     
     this.idle.onTimeout.subscribe(() => {
       $('#idlemodel').show();
       this.idleState = 'Timed out!';
       this.timedOut = true;      
       this.endChat();
     });
     this.idle.onIdleStart.subscribe(() => {
         this.idleState = 'You\'ve gone idle!'       
         $('#idlemodel').show();
     });   
     this.idle.onTimeoutWarning.subscribe((countdown) => {
       this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
     });
     this.keepalive.interval(15);
     this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
       if (sessionStorage.getItem('sessionid')) {
         this.idle.watch()
         this.timedOut = false;
       } else {
        this.idle.stop();
       }

   
    
    
     window.addEventListener('message',(event) => {
      if(this._api.WhitelistDomainForpostmessage.indexOf(event.origin)>-1){
        if(event.data?event.data.from=='user':false){
          this.handleMessage();
        }else if(event.data?event.data.from=='Customer':false){
          this.handleMessage();
        }
      }
    })
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
  getLocation(href) {
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
  }
  stay() {
    $('#idlemodel').hide();
    this.reset();
  }
  idleLogout() {
    this.endChat()
  }
  async ngOnInit() {
    this.updateShowSnoozeLastDayOption()
    var url = (window.location != window.parent.location) ? document.referrer : document.location;
    var host = '';
    if(url && url!=null && typeof url=='string'){
      let location = this.getLocation(url);
      host=location.host?location.host:'';
     }
     if(this._api.HideSearchIconFor.indexOf(host)>-1){
      this.EnbleSerch=false
     }else{
       this.EnbleSerch=true
     }
    var scriptUrl = this._api.baseUrl+'socketwindow';
    let node = document.createElement('iframe');
    node.src = scriptUrl;
    node.className = "d-none";
    this.chathide = 1;
    this.aid = 0;
    this.adminnotconnect = 0;
      this.endsess = 1;
    this.convertbtn_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
    this.endsession = false;
    document.getElementById('chat-windowTag').appendChild(node);
    var SessUserData = this._auth.getUserDetails();
    var sessUser = SessUserData[0];
    this.UserId = sessUser.id;
    this.msgType = this._auth.getUsertype() == 'Admin'?0:1;
    if(sessUser.aid){
      this.sessID = this._auth.getUsertype() == 'Admin'?sessUser.id:sessUser.aid;
      this.messages = [];
      this.FrndId = sessUser.aid;
      this.aid = sessUser.aid;
      if(!this.FrndId){
       await this.getFrndId(this.UserId)
      }
      this._api.getTypeRequest('message/users/'+this.FrndId).subscribe((res:any) => {
        var data = this._api.decrypt(res.data);
        // //("user",data)
        this.selectFriendToChat(data[0]); 
      });
      this._api.getTypeRequest('message/adminids/'+this.sessID).subscribe((res:any) => {
        this.adminId = res.data[0].aid;
      });
      if(this._auth.getUsertype()=='admin'){
      this.GetSnoozeData()
      }
    this._api.getTypeRequest('user/customeradminsession/'+this.UserId+'/'+this.FrndId).subscribe((res: any) => {
      var settingsVal = this._api.decrypt(res.settings)[0];
       this.settingsVal = this._api.decrypt(res.settings)[0];
      //console.log(settingsVal)
      var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
      var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
       if(openTime && !openDay){
        this.enablesnooze=true
       }else{
        this.enablesnooze=false
       }
     })
    } else {
      if(!sessionStorage.getItem('sessionid')){
        this.FrndId = 0;
        this.selectedFriendName = 'Message Us';
        this.selectedFriendId = 0;
      }
      this._api.getTypeRequest('message/adminids/0').subscribe((res:any) => {
        this.adminId = res.data[0].aid;
      });
      if(this.msgType == 1){
        this.getMessages(this.UserId);
        this.handleMessage();
      }else{
        this.getRespond(this.UserId);}
    }
    
  }
  async AfterChatend(){
    var scriptUrl = this._api.baseUrl+'socketwindow';
    let node = document.createElement('iframe');
    node.src = scriptUrl;
    node.className = "d-none";
    this.chathide = 1;
    this.aid = 0;
    this.adminnotconnect = 0;
      this.endsess =0;
    this.convertbtn_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
    var SessUserData = this._auth.getUserDetails();
    var sessUser = SessUserData[0];
    this.UserId = sessUser.id;
    this.msgType = this._auth.getUsertype() == 'Admin'?0:1;
    if(sessUser.aid){
      this.sessID = this._auth.getUsertype() == 'Admin'?sessUser.id:sessUser.aid;
      this.messages = [];
      this.FrndId = sessUser.aid;
      this.aid = sessUser.aid;
      if(!this.FrndId){
        await this.getFrndId(this.UserId)
       }
      this._api.getTypeRequest('message/users/'+this.FrndId).subscribe((res:any) => {
        var data = this._api.decrypt(res.data);
        this.selectFriendToChat(data[0]); 
      });
      this._api.getTypeRequest('message/adminids/'+this.sessID).subscribe((res:any) => {
        this.adminId = res.data[0].aid;
      });
    } else {
      if(!sessionStorage.getItem('sessionid')){
        this.FrndId = 0;
        this.selectedFriendName = 'Message Us';
        this.selectedFriendId = 0;
      }
      this._api.getTypeRequest('message/adminids/0').subscribe((res:any) => {
        this.adminId = res.data[0].aid;
      });
      if(this.msgType == 1){
        this.getMessages(this.UserId);
        this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
      }else{
        this.getRespond(this.UserId);}
    }
  }
  getFrndId(UserId){
    return new Promise(resolve=>{
      this._api.getTypeRequest('message/conversation/'+UserId).subscribe((res:any) => {
        var dataval = res.data[0];
      var dataval = res.data[0];
      if(res.data && res.data.length >0 && dataval.rid > 0){
        this.FrndId = this.UserId == dataval.sid?dataval.rid:dataval.sid;
        resolve('')
      }else{
        resolve('')
      }
    },err=>{
      resolve('')
    })
    })
   
  }
  async handleMessage() {
  if(this.msgType == 1 && !this.FrndId){
    this._api.getTypeRequest('message/conversation/'+this.UserId).subscribe((res:any) => {
      var dataval = res.data[0];
      if(res.data && res.data.length >0 && dataval.rid > 0){
        this.FrndId = this.UserId == dataval.sid?dataval.rid:dataval.sid;
        this.getMessages(this.UserId);
        this._auth.setDataInLocalStorage('sessionid', dataval.id);
        this.idle.watch()
        this.timedOut = false;
        this._api.getTypeRequest('user/customeradminsession/'+this.UserId+'/'+this.FrndId).subscribe((result: any) => {
          var settingsVal = this._api.decrypt(result.settings)[0];
          var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
          var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
          this.aid = 1;
          if(openTime && !openDay){
            this._auth.setDataInLocalStorage('userData', result.data);  
            this._auth.setDataInLocalStorage('token', result.token);    
            this._auth.setDataInLocalStorage('userType', 'User');    
            this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone)); 
            // this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
          }
        });
      } else if(this.FrndId){
        this.getMessages(this.UserId);
      }
      // else {
      //   if(!sessionStorage.getItem('sessionid')){
      //     var dataval = res.data[0];
      //     if(dataval){
      //       this._auth.setDataInLocalStorage('sessionid', dataval.id);
      //       this.idle.watch()
      //       this.timedOut = false;
      //       this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
      //     }
      //   }
      // }
    });
  } 
  if(this._auth.getUserDetails()){
   
    var SessUserData = this._auth.getUserDetails();
      var sessUser = SessUserData[0];
      this.UserId = sessUser.id;
      this.msgType = this._auth.getUsertype() == 'Admin'?0:1;
      if(this.aid){
        this.chathide = 1;
        this.adminReq = [];
        this.sessID = this._auth.getUsertype() == 'Admin'?sessUser.id:sessUser.aid;
        this.FrndId = sessUser.aid;
        if(!this.FrndId){
         await this.getFrndId(this.UserId)
        }
        this._api.getTypeRequest('message/users/'+this.FrndId).subscribe((res:any) => {
          var data = this._api.decrypt(res.data);
          this.selectFriendToChat(data[0]);
          if(this.msgType == 1 && sessionStorage.getItem('sessionid')){
           
            this.getRespondUser(this.UserId);
          }
          if(this.msgType == 0 && sessionStorage.getItem('sessionid')){
           
            this.getRespond(this.FrndId);
          }
        });
    } else{
      if(this.msgType == 0 && !this.FrndId){
        
        this.getRespond(this.UserId);}
    }
  } else {
    if(this.msgType == 0 && !this.FrndId){
     
    this.getRespond(this.UserId);}
  }
  this.convertbtn_id = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
  if(sessionStorage.getItem('sessionid')){
    this.endsess = sessionStorage.getItem('sessionid');
  }
}

isOpen = (openTime, closeTime, timezone) => {
  const now = moments().tz(timezone);
  const storeOpenTime = moments.tz(now.format('YYYY-mm-dd')+' '+openTime, "YYYY-mm-dd H:mm:ss", timezone);
  const storeCloseTime = moments.tz(now.format('YYYY-mm-dd')+' '+closeTime, "YYYY-mm-dd H:mm:ss", timezone);
  return now.isBetween(storeOpenTime, storeCloseTime);
}
isOpenDay = (days, timezone) => {
  const now = moments().tz(timezone);
  var daySplit = days.split(",");
  return daySplit.indexOf(now.format('ddd')) !== -1;
}
getRespondUser(id){
  this._api.getTypeRequest('message/checkusrconvert/'+id).subscribe((res:any) => {
    var data = res.data;
    if(data.length == 0){
      this.endChat();
    }
  });
}
getRespond(id){
  this._api.getTypeRequest('message/checkmessages/'+id).subscribe((res:any) => {
    var data = res.data;
    if(data.length == 0){
      this.getMessages(this.UserId);
      this.endChat();
    } else {
      if(!sessionStorage.getItem('sessionid'))
        this.acceptChat(0,this.FrndId);
    }
  });
}
acceptChat(id,fid){
  this._api.getTypeRequest('message/acceptchat/'+fid+'/'+this.UserId+'/'+id).subscribe(async (res:any) => {
    if(res.data.length > 0){
       sessionStorage.clear();
       this.FrndId = fid;  
       if(!this.FrndId){
        await this.getFrndId(this.UserId)
       }
       this._auth.setDataInLocalStorage('sessionid', res.data[0].id);  
       this.idle.watch()
       this.timedOut = false;
       this._api.getTypeRequest('user/admincustomersession/'+fid+'/'+this.UserId).subscribe((res: any) => {
        var settingsVal = this._api.decrypt(res.settings)[0];
        var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
        var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
        var url = (window.location != window.parent.location) ? document.referrer : document.location;
        var host = '';
        let showalert=true
        if(url && url!=null && typeof url=='string'){
          let location = this.getLocation(url);
          host=location.host?location.host:'';
         }
         if(this._api.HideSearchIconFor.indexOf(host)>-1){
          showalert=false
         }
        if((openTime && !openDay)||showalert){
          this._auth.setDataInLocalStorage('userData', res.data);  
          this._auth.setDataInLocalStorage('token', res.token);
          this._auth.setDataInLocalStorage('customeradmin', true); 
          this._auth.setDataInLocalStorage('userType', 'Admin');    
          this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone)); 
          this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
        } else{
          this._auth.setDataInLocalStorage('cutofEmail', settingsVal.email);  
          this._router.navigate(['/cutoftime']);
        }
      });
    }
  });
  return false;
}
endChat(){
  $('.confirmmodel').hide();
  $('#idlemodel').hide();
  if(sessionStorage.getItem('sessionid')){
    this._api.getTypeRequest('message/endchat/'+sessionStorage.getItem('sessionid')).subscribe((res:any) => {
      if(sessionStorage.getItem('customeradmin')){
        sessionStorage.removeItem('sessionid');
        this.idle.stop();
       this.AfterChatend();
       this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
      } else {
        sessionStorage.clear();
        this._api.getTypeRequest('user/customeradminsession/'+this.UserId+'/0').subscribe((res: any) => {
          var settingsVal = this._api.decrypt(res.settings)[0];
          var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
          var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
          if(openTime && !openDay){
            this._auth.setDataInLocalStorage('userData', res.data);  
            this._auth.setDataInLocalStorage('token', res.token); 
            var usrType = this.msgType == 0?'Admin':'User'; 
            this._auth.setDataInLocalStorage('userType', usrType);    
            this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone)); 
            this.AfterChatend();
            this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
            
          }
        });
      }
    });
  }
  return false;
}
endalready(){
  $('.alreadymodel').hide();
}
endChatConfirm(){
  $('.confirmmodel').show();
}
confirmno(){
  $('.confirmmodel').hide();
} 
dismissChat(id,fid){
  var divLen = $('.chatreqlen:visible').length;
  $('#chat'+id+fid).hide();
  if(divLen == 1)
    $('.main').hide();
  return false;
}
logout(){
  this._auth.clearStorage()
  this._api.getTypeRequest('user/logout/'+this.UserId).subscribe();
  this._router.navigate(['/login']);
}
trimedCkEditorText(str) {
  let contentStr = str;
  contentStr = this.trimedCkEditorTextAt(contentStr, true);
  contentStr = this.trimedCkEditorTextAt(contentStr, false);
  return contentStr;
}
trimedCkEditorTextAt(contentStr, startOfText) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentStr, "text/html")
  while(doc.body.children.length) {
      const index = startOfText ? 0 : doc.body.children.length - 1; 
      const child = doc.body.children[index];    
      if(child.textContent.replace(/\s/g, '').length) {
          while(child.children.length) {
              const index = startOfText ? 0 : child.children.length - 1; 
              const grandechild = child.children[index];
              if(grandechild.localName == 'br') grandechild.remove();
              else break;
          }
          const childTextArray = child.innerHTML.split(' ');
          while(childTextArray.length) {
              const index = startOfText ? 0 : childTextArray.length - 1; 
              if(childTextArray[index] == '&nbsp;') childTextArray.splice(index, 1);
              else break;
          }
          child.innerHTML = childTextArray.join(' ');
          break;
      } else {
          child.remove();
      }
  }
  return doc.body.innerHTML;
}
isValidHttpUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol == 'http:' || url.protocol == 'https:';
  } catch (err) {
    return false;
  }
}
  gethttpURL(txt){
    var urls = txt.match(/\b(http|https)?(:\/\/)?(\S*)\.(\w{2,4})\b/ig);
    var newStr = txt;
    if(urls && urls.length > 0){
      var txtURL = [];
      for (var i = 0, il = urls.length; i < il; i++) {
          txtURL.push(urls[i]);
      }
      (txtURL);
      for(var j = 0;j < txtURL.length; j++){
        var url = this.isValidHttpUrl(txtURL[j])?txtURL[j]:'http://'+txtURL[j];
        newStr = newStr.replace(txtURL[j], '<a href="'+url+'" target="_blank">'+txtURL[j]+'</a>');
      }
    }
    return newStr;
  }
sendMessageClick() {
  var message = this.message;
  message = this.gethttpURL(message);
  message = this.trimedCkEditorText(message);
    var repMes = this.repMessage?this.repMessage:'';
    if(message){
      var adminid = '';
      if(this.msgType == 1 && !sessionStorage.getItem('sessionid'))
        adminid = this.adminId==''?'':this.adminId;
        var conid = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
        var messagePacket = {
            message: message,
            fromUserId: this.UserId,
            toUserId: this.FrndId,
            adminid: adminid,
            con_id: conid,
            cid:this.msgType==1?this.UserId:this.FrndId,
            date:this.timzoneTime(),
            type:this.msgType
        };
        $('#sendloader').show()
        this._api.postTypeRequest('message/insert', { data:this._api.encryptArray(messagePacket)}).subscribe(async(res: any) => {(res)
          // //("insert",res)
          if(this._auth.getUsertype()!='User'){   
            await this.UpdateUnread(this.FrndId)
          }
          if(res.already && res.already ==1){
            $('#sendloader').hide()
            if(this._auth.getUsertype()=='User'){          
            await this.getMessages(this.UserId);           
            this.endsess = 1;
            this.message = '';
            this._api.postTypeRequest('sendmessage', messagePacket).subscribe((res: any) => {(res)});
            this._api.postThirdparyurl(this._api.notifyTrigger,'').subscribe((res: any) => { ('one'+res)});
            }else{
            $('.alreadymodel').show();
            }
          } else {
           
            this._api.postTypeRequest('AlertUser', {userid:this.UserId}).subscribe((res: any) => {});
            await this.getMessages(this.UserId);
            if(this.FrndId && !sessionStorage.getItem('sessionid') && this.msgType == 0){
              var data = this._api.decrypt(res.data);
              this._auth.setDataInLocalStorage('sessionid', data[0].con_id);  
              this.idle.watch()
              this.timedOut = false;
            }
            this.endsess = 1;
            this.message = '';
            this._api.postThirdparyurl(this._api.notifyTrigger,'').subscribe((res: any) => { });
          }
          });
          message = '';
          this.message = '';
          this.repMessage?this.closereplymessage():'';
        
    }
}
UpdateUnread(touserid){
  return new Promise(resolve=>{
  this._api.getTypeRequest('message/UpdateUnreadmsg/'+touserid).subscribe((res:any) => {
   resolve(res)
  },err=>{
    resolve(err)
  });
})
}
getMessageWidth() {
  if (this._auth.getUsertype() === 'User') {
    return 'user';
  } else {
    return 'admin';
  }
}
selectFriendToChat (data) {
  const friendData = data;
  this.selectedFriendName = friendData.firstname;
  this.selectedFriendId = friendData.id;
  this.getMessages(this.UserId,friendData.id);
}
todayString(mdate,disp){
  var today = new Date();
  var mesdate = new Date(mdate);
  var msdate = new Date(mdate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric'}).replace(/ /g, '/');
  return today.toDateString() == mesdate.toDateString() && disp == 'chat'?'Today':msdate;
}
timzoneTime(){
  const givDate = moments().tz(this._auth.getTimezone());
  var mesdate = givDate.format('YYYY-MM-DD H:mm:ss');
  return mesdate;
}
datewiseMessages(data){
  var messArray = [];
    var i=0;
    data.forEach(obj => {
      var dateStr = obj.mdate.split('T')[0];
      //messArray[i] = {date:dateStr,data:obj};
      if(!messArray[i]){
        messArray[i] =[];
        messArray[i]['date'] = dateStr;
        messArray[i]['data'] = [];
        messArray[i]['data'].push(obj);
      } else if(messArray[i]['date'] == dateStr) {
        messArray[i]['data'].push(obj);
      } else {
        i++;
        messArray[i] =[];
        messArray[i]['date'] = dateStr;
        messArray[i]['data'] = [];
        messArray[i]['data'].push(obj);
      }
    });
    this.scrollToBottom();
    return messArray;
}
  async getMessages(userId,frdId = null) {
  userId = this.msgType == 0 ? this.FrndId:this.UserId;
  if(!this.FrndId){
    await this.getFrndId(this.UserId)
   }
  if(this.msgType == 0){
    this._api.getTypeRequest('message/messagesread/'+this.UserId+'/'+this.FrndId).subscribe((res:any) => {
      this._api.getTypeRequest('message/messages/'+userId+'/'+this.FrndId).subscribe((res:any) => {
        var data = this._api.decrypt(res.data);
        // for(var di=0;di<data.length;di++){
        //   // data[di].message = this._api.decryptString(data[di].message);
        //   if(data[di].con_id == 0 || data[di].toUserId == 0){
        //     this.chathide = 0;
        //   }
        //   this.adminnotconnect = 0
        //   if(data[di].toUserId == 0 && data[di].endsess == 0){
        //     this.adminnotconnect = 1
        //   }
        // }
        // //("message",res)
        if(data.length){
        if(data[data.length-1].con_id == 0 || data[data.length-1].toUserId == 0){
          this.chathide = 0;
        }
        this.adminnotconnect = 0
        if(data[data.length-1].toUserId == 0 && data[data.length-1].endsess == 0){
          this.adminnotconnect = 1
        }
      }
        this.messages = this.datewiseMessages(data);
        //(this.messages)
        if(res.datacnt && res.datacnt[0].unread > 0)
          this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
      });
    });
  } else {
    this._api.getTypeRequest('message/messages/'+userId+'/'+this.FrndId).subscribe((res:any) => {
      var data = this._api.decrypt(res.data);
      // for(var di=0;di<data.length;di++){
      //   data[di].message = this._api.decryptString(data[di].message);
      //   if(data[di].con_id == 0 || data[di].toUserId == 0){
      //     this.chathide = 0;
      //   }
      //   this.adminnotconnect = 0
      //   if(data[di].toUserId == 0 && data[di].endsess == 0){
      //     this.adminnotconnect = 1
      //   }
      // }
      // //("message",res)
      if(data.length){
      if(data[data.length-1].con_id == 0 || data[data.length-1].toUserId == 0){
        this.chathide = 0;
      }
      this.adminnotconnect = 0
      if(data[data.length-1].toUserId == 0 && data[data.length-1].endsess == 0){
        this.adminnotconnect = 1
      }
    }
      
      this.messages = this.datewiseMessages(data);
      if(res.datacnt && res.datacnt[0].unread > 0)
        this._api.postTypeRequest('sendmessage', '').subscribe((res: any) => {(res)});
        this.scrollToBottom();
    });
  }
}
alignMessage(fromUserId) {
  if(this.msgType == 1)
    return fromUserId == 'Admin' ? false : true;
  else
  return fromUserId == 'Admin' ? true : false;
}
scrollToBottom(){
  const messageThread = document.getElementById('users-conversation');
  setTimeout(() => {
    if(messageThread&&messageThread.lastElementChild&&messageThread.lastElementChild.querySelector('li:last-child')&&messageThread.lastElementChild.querySelector('li:last-child').id){
      var chatelmid = messageThread.lastElementChild.querySelector('li:last-child').id;
      messageThread.scrollTop = messageThread.scrollHeight + 500;
      document.getElementById(chatelmid).scrollIntoView();
    }
  }, 1000);  
  setTimeout(() => {
    $('#sendloader').hide()
  }, 100);
}
closereplymessage() {
  var i = document.querySelector(".replyCard");
  i.classList.remove("show");
  this.repMessage = '';
}
searchMessage() {
  var input, filter, ul, li, a, i, j, div;
  for (input = document.getElementById("searchChatMessage"), filter = input.value.toUpperCase(), ul = document.querySelector(".chat-history"), li = ul.getElementsByTagName("li"), i = 0; i < li.length; i++) {
      -1 < li[i].innerText.toUpperCase().indexOf(filter) ? (li[i].style.display = "") : (li[i].style.display = "none");
  }
}
}