declare var require: any
import { Component, OnInit,NgModule } from '@angular/core';
import {NgForm} from '@angular/forms';
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
var moment = require('moment-timezone');

@Component({  
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLogin: boolean = false
  errorMessage: any;
  constructor(
    private _api: ApiService, 
    private _auth: AuthService,
    private _router:Router,
    public activeRoute: ActivatedRoute
  ) { }
  getLocation(href) {
    var match = href.match(/^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    }
  }
  isOpen = (openTime, closeTime, timezone) => {
    const now = moment().tz(timezone);
    const storeOpenTime = moment.tz(now.format('YYYY-mm-dd')+' '+openTime, "YYYY-mm-dd H:mm:ss", timezone);
    const storeCloseTime = moment.tz(now.format('YYYY-mm-dd')+' '+closeTime, "YYYY-mm-dd H:mm:ss", timezone);
    //console.log(now.format('ddd')+'=='+now.format('H:mm:ss')+'=='+storeOpenTime.format('ddd, H:mm:ss')+'==='+storeCloseTime.format('ddd, H:mm:ss'))
    return now.isBetween(storeOpenTime, storeCloseTime);
  }
  isOpenDay = (days, timezone) => {
    const now = moment().tz(timezone);
    var daySplit = days.split(",");
    //console.log(daySplit.indexOf(now.format('ddd')) !== -1) 
    return daySplit.indexOf(now.format('ddd')) !== -1;
  }
  ngOnInit() {
   
    var url = (window.location != window.parent.location) ? document.referrer : document.location;
    var host = '';
    if(url && url!=null && typeof url=='string'){
      let location = this.getLocation(url);
      host=location.host?location.host:'';
     }
      // if(window.location !== window.parent.location && this._api.DomainName.indexOf(host) !== -1 ){
      this.isUserLogin();  
    const atoken = this.activeRoute.snapshot.queryParamMap.get('atoken');
    const token = this.activeRoute.snapshot.queryParamMap.get('token');
    const agentId = 0;//this.activeRoute.snapshot.queryParamMap.get('agentid');
    const cusId = this.activeRoute.snapshot.queryParamMap.get('cusid');
    const aagentId = this.activeRoute.snapshot.queryParamMap.get('agentid');
    const snooze =this.activeRoute.snapshot.queryParamMap.get('snooze')
    if(atoken){
      this._api.getTypeRequest('user/admintoken/'+atoken).subscribe((res: any) => {
         if (res.status) {
          sessionStorage.clear()
          var settingsVal = this._api.decrypt(res.settings)[0];
          var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
          var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
          //  if(openTime && !openDay){
            this._auth.setDataInLocalStorage('userData', res.data);  
            this._auth.setDataInLocalStorage('token', res.token);    
            this._auth.setDataInLocalStorage('userType', 'Admin'); 
            this._auth.setDataInLocalStorage('customeradmin', true);    
            this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone));    
            this._auth.setDataInLocalStorage('idletime', settingsVal.idletime); 
            this._auth.clearSnooze()
            if(snooze){
              console.log("message-login-snooze",snooze)
              this._auth.setDataInLocalStorage('snooze', 'true')
              this._router.navigate(['/messages']);
            }else{
              console.log("message-login-snooze- no",snooze)
              this._auth.setDataInLocalStorage('snooze', 'false')
              this._router.navigate(['/messages']);
            }
            
          // } else{
          //   this._auth.setDataInLocalStorage('cutofEmail', settingsVal.email);  
          //   this._router.navigate(['/cutoftime']);
          // }
         } else {
           this.errorMessage = res.error;
         }
       })
    }else if(token){
      this._api.getTypeRequest('user/customeradmin/'+token+'/'+agentId).subscribe((res: any) => {
         if (res.status) { 
          //var sessionId = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
          sessionStorage.clear()
          var settingsVal = this._api.decrypt(res.settings)[0];
          var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
          var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
           if(openTime && !openDay){
            if(res.sessionid > 0)
               this._auth.setDataInLocalStorage('sessionid', res.sessionid); 
            this._auth.setDataInLocalStorage('userData', res.data);  
            this._auth.setDataInLocalStorage('token', res.token);    
            this._auth.setDataInLocalStorage('userType', 'User');    
            this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone));   
            this._auth.setDataInLocalStorage('idletime', settingsVal.idletime); 
            this._router.navigate(['/message']);
          } else{
            this._auth.setDataInLocalStorage('cutofEmail', settingsVal.email);  
            this._router.navigate(['/cutoftime']);
          }
         } else {
           this.errorMessage = res.error;
         }
       })
    }else if(cusId && aagentId){
      this._api.getTypeRequest('user/admincustomer/'+cusId+'/'+aagentId).subscribe((res: any) => {
         if (res.status) { 
          //var sessionId = sessionStorage.getItem('sessionid')?sessionStorage.getItem('sessionid'):0;
          sessionStorage.clear()
          var settingsVal = this._api.decrypt(res.settings)[0];
          var openTime = this.isOpen(settingsVal.starttime, settingsVal.endtime, settingsVal.timezone);
          var openDay = this.isOpenDay(settingsVal.offdays, settingsVal.timezone);
          //  if(openTime && !openDay){
            if(res.sessionid > 0)
               this._auth.setDataInLocalStorage('sessionid', res.sessionid); 
            this._auth.setDataInLocalStorage('userData', res.data); 
            this._auth.setDataInLocalStorage('customeradmin', true); 
            this._auth.setDataInLocalStorage('token', res.token);    
            this._auth.setDataInLocalStorage('userType', 'Admin');    
            this._auth.setDataInLocalStorage('timezone', this._api.encryptString(settingsVal.timezone));   
            this._auth.setDataInLocalStorage('idletime', settingsVal.idletime); 
            this._router.navigate(['/message']);
          // } else{
          //   this._auth.setDataInLocalStorage('cutofEmail', settingsVal.email);  
          //   this._router.navigate(['/cutoftime']);
          // }
         } else {
           this.errorMessage = res.error;
         }
       })
    }
  // } else 
  // this._router.navigate(['/unauthorized']);
  }

  
  onSubmit(form: NgForm) {
    this._api.postTypeRequest('user/login', form.value).subscribe((res: any) => {
      if (res.status) { 
       
        this._auth.setDataInLocalStorage('userData', JSON.stringify(res.data));  
        this._auth.setDataInLocalStorage('token', res.token);     
        this._auth.setDataInLocalStorage('userType', 'User');  
        if(res.data[0].type == 'Admin'){}
       
       
      } else {
        this.errorMessage = res.error;
      }
    })

  }

  navregister(){
    this._router.navigate(['/register']);
  }
  isUserLogin(){

    if(this._auth.getUserDetails() != null){

        this.isLogin = true;
        //this._router.navigate(['/message']);
        var logDetails = this._auth.getUserDetails();
        if(this._auth.getUsertype() == 'Admin'){
          const snooze =this.activeRoute.snapshot.queryParamMap.get('snooze')
          if(snooze){
            console.log("message-login-snooze",snooze)
            this._auth.setDataInLocalStorage('snooze', 'true')
            this._router.navigate(['/messages']);
          }else{
            console.log("message-login-snooze- no",snooze)
            this._auth.setDataInLocalStorage('snooze', 'false')
            this._router.navigate(['/messages']);
          }
        }
        else{
          this._router.navigate(['/message']);
        }
    }

  }

  logout(){
    this._auth.clearStorage()
    this._router.navigate(['']);
  }
}