import { Component, OnInit, ViewEncapsulation } from '@angular/core';
declare var $: any;
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-message',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './cutoftime.component.html',
  styleUrls: ['../../../assets/css/min-bootstrap.min.css','../../../assets/css/font-awesome.min.css','../../../assets/css/chat.css']
})
export class CutoftimeComponent implements OnInit {
  public email: any;

  constructor( private _api: ApiService, ){
 
    window.addEventListener("message", (event) => {
      if(event.data.from =="model-close"){
        console.log("comming")
        window.parent.postMessage({data:"checkuser",process:"proceed"},this._api.ClosemodelreportURL)
      }
   }, false)
  }

  ngOnInit() {
    $('#CutofTimeMsg').show();
    console.log(localStorage.getItem('cutofEmail'));
    this.email = localStorage.getItem('cutofEmail');
  }
}
