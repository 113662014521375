import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _api: ApiService,) { }
  
     getUserDetails() {
    if(sessionStorage.getItem('userData')){
      // console.log(sessionStorage.getItem('userData'))
      var data = this._api.decrypt(sessionStorage.getItem('userData'));
      return data;

    }else{
      return null
    }
    
  }

  setDataInLocalStorage(variableName, data) {
    sessionStorage.setItem(variableName, data);
  }

  getToken() {
      return sessionStorage.getItem('token');
  }

  getTimezone() {
    return this._api.decryptString(sessionStorage.getItem('timezone'));
}

  getUsertype() {
    return sessionStorage.getItem('userType');
}
  IsSnooze() {
    return sessionStorage.getItem('snooze');
}
clearSnooze(){
  sessionStorage.removeItem('snooze');
}

  clearStorage() {
    sessionStorage.clear();
  }
}