import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { UnauthorizedComponent } from './auth/components/unauthorized/unauthorized.component';
import { MessagesComponent } from './main/messages/messages.component';
import { MessageComponent } from './main/message/message.component';
import { CutoftimeComponent } from './main/cutoftime/cutoftime.component';
import { AlreadyComponent } from './main/already/already.component';
import { AuthGuardService } from './services/auth-guard.service';
import { SnoozeComponent } from './auth/components/snooze-alert/snooze-alert.component';

const routes: Routes = [
  { path: '', redirectTo: '/index.html', pathMatch: 'full' },
  {path: 'login', component: LoginComponent},
  {path: 'snooze-alert', component: SnoozeComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'messages', canActivate: [AuthGuardService], component: MessagesComponent},
  {path: 'message', canActivate: [AuthGuardService], component: MessageComponent},
  {path: 'cutoftime', component: CutoftimeComponent},
  {path: 'already', component: AlreadyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
